<template>
    <div class="searchbox-inline">
        <div id="search_results_overlay" v-if="isEditMode"></div>
        <div class="searchbox">

            <!-- TABS -->
            <div class="row tabs" v-if="currentTab == 'hotel'">
                <div class="tab" :class="{'active': currentTab == 'parking'}" @click="setTab('parking')">Airport Parking Only</div>
                <div class="tab" :class="{'active': currentTab == 'hotel'}" @click="setTab('hotel')">Hotel & Parking Package</div>
                <!--<div class="tab" :class="{'active': currentTab == 'shuttle'}" @click="setTab('shuttle')" v-if="showSf">Shuttle Transport</div>-->
            </div>
            
            <div class="searchbox" style="display: flex; align-items: center; background-color: #F5F8F8;">
                <!-- FIELDS -->
                <form action="" method="post" autocomplete="off" ref="searchboxForm" style="flex: 1;">
                    <display-apr v-if="currentTab == 'parking' && (packageType == 'APR' && !isEditMode)"></display-apr>
                    <display-psf v-if="currentTab == 'hotel' && (packageType == 'PSF' && !isEditMode)"></display-psf>
                    <option-apr v-if="currentTab == 'parking' && (isEditMode || packageType == 'PSF')" style="max-width: 1000px;"></option-apr>
                    <option-psf v-else-if="currentTab == 'hotel' && (isEditMode || packageType == 'APR')" style="max-width: 1100px;"></option-psf>
                     <div style="text-align: center; margin-bottom: 1em;"
                        v-if="showServiceFee && currentTab == 'hotel' && (isEditMode || packageType == 'APR')">
                        All bookings will be charged ${{ serviceFee }} service fee
                    </div> 
                  <option-sf v-else-if="currentTab == 'shuttle'"></option-sf>
                </form>
                 <div style="padding: 0 15px;" 
                    v-if="showServiceFee && ((currentTab == 'parking' && (isEditMode || packageType == 'APR')) || !isEditMode)">
                    All bookings will be charged ${{ serviceFee }} service fee
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import SearchboxMixin from "./SearchboxMixin.js";
    import RequestMixin from "./Requests.js";
    import OptionAPR from "./options/OptionsAPR.vue";
    import OptionPSF from "./options/OptionsPSF.vue";
    import OptionSF from "./options/OptionsSF.vue";
    import DisplayAPR from "./display/DisplayAPR.vue";
    import DisplayPSF from "./display/DisplayPSF.vue";

    export default {
        mixins: [ SearchboxMixin, RequestMixin ],
        components: {
            'option-apr': OptionAPR,
            'option-psf': OptionPSF,
            'option-sf': OptionSF,
            'display-apr': DisplayAPR,
            'display-psf': DisplayPSF,
        },

        data() {
            return {
                type: 'searchbox-inline',
                isEditMode: false,
                packageType: '',
            }
        },
        computed: {
            showServiceFee() {
                const aprCode = apr.search_apr && apr.search_apr.airport ? apr.search_apr.airport.code : '';
                const psfCode = apr.search_psf && apr.search_psf.airport ? apr.search_psf.airport.code : '';
                return aprCode === 'MSP' || psfCode === 'MSP';
            },
            serviceFee() {
                return window.apr.serviceFee;
            }
        },
        mounted() {
            this.initSearchboxForm()
            if (apr.search_apr.search.data.token) {
                this.packageType = 'APR';
                this.setData(apr.search_apr);
            } else if (apr.search_psf.search.data.token) {
                this.packageType = 'PSF';
                this.setData(apr.search_psf);
            }
            this.$store.dispatch('updateSessionTab', this.currentTab);
        },

        methods: {
            setData(data) {
                let search = {
                    id: data.search.data.token,
                    airport: {
                        type: data.search.data.airport.data.type,
                        code: data.search.data.airport.data.code,
                        name: data.search.data.airport.data.name,
                        city: data.search.data.airport.data.address.city,
                        state_code: data.search.data.airport.data.address.state_code,
                        latitude: data.search.data.airport.data.geo.latitude,
                        longitude: data.search.data.airport.data.geo.longitude,
                    },
                    date1: data.search.data.checkindate.date_format_2,
                    date2: data.search.data.checkoutdate.date_format_2,
                    dates: data.search_details ? data.search_details.dates : null,
                    package: data.search_details ? data.search_details.package_type : data.search.data.package,
                    guests: data.search.data.guests,
                    rooms: data.search.data.rooms
                };
                console.log(search)
                let tab = data.search.data.package == 'APR' ? 'parking' : 'hotel';
                this.$store.dispatch('updateTab', tab)
                this.$store.dispatch('updateSession', search);
            },

            setTab(tab) {
                if (this.sessionTab != tab) {
                    this.isEditMode = true
                }
                if (tab == 'parking') {
                    this.$store.dispatch('updatePackage', 'APR');
                } else {
                    this.$store.dispatch('updatePackage', this.session.package == 'APR' ? null : this.session.package);
                }
                this.$store.dispatch('updateTab', tab);
            },

            getPageHeading(tagName) {
                if (this.currentTab == 'hotel') {
                    return this.headings.psf[tagName]
                }
                if (this.currentTab == 'shuttle') {
                    return this.headings.sf[tagName]
                }
                return this.headings.apr[tagName]
            },
        }
    };
</script>
